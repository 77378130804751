import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../../email.service';
import { EventService } from '../../../events.service';
import { HttpClient } from "@angular/common/http";
import { FormBuilder } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import { Router } from "@angular/router";

@Component({
  selector: 'app-terms',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  public checkoutEventForm;
  showMsgSuccess: boolean = false;
  showMsgError: boolean = false;
  isInactive: boolean = false;
  errorEventMessage : string;
  id_event: string;
  timeEST: string;
  start_date: string;
  eventData: any = [];
  dataSubmit : any = [];
  html_left_section: any;
  html_right_section: any;
  url_video: any;
  
  constructor(private http: HttpClient,
    private route: ActivatedRoute, 
    private _emailService: EmailService, 
    private _getEvenService: EventService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router) { 
      this.checkoutEventForm = this.formBuilder.group({
        names: null,
        email: null,
        phone: null,
        message: null
      });

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      route.params.subscribe(value => {
        this._getEvenService.getLastIdEventService().subscribe(res => {
          let eventResponse:any = res;
          let eventData:any = eventResponse.data;
          let lastIdEvent = eventData.id;
          let idEventFromUrl = value.id;
          if (lastIdEvent != idEventFromUrl) {
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([`events/${lastIdEvent}`]);
          }
        });
      })
    }

  ngOnInit() {
    this.route.params.subscribe(params =>{
      this.id_event = params['id'];
    });
    this.getEvent(this.id_event);
  }

  onSubmit(subscriberData) {
    this.dataSubmit= {
      "names": subscriberData.names,
      "email": subscriberData.email,
      "phone": subscriberData.phone,
      "message": subscriberData.message,
      "event_id": this.id_event
    };
    this._emailService.sendEventEmail(this.dataSubmit).subscribe(
      () => { this.showMsgError= false; this.showMsgSuccess= true; console.log('sent'); },
      err =>  { this.showMsgSuccess= false; this.showMsgError= true; console.log(err); } 
    );
    this.checkoutEventForm.reset();
  }

  getEvent(id_event: string){
		this._getEvenService.getEventService(id_event).subscribe(res => {
        let eventResponse:any = res;
          this.eventData = eventResponse.data;
          this.timeEST = this.eventData.start_date.substring(11, 18);
          this.start_date = this.eventData.start_date.substring(0, 12);
          this.url_video = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.eventData.video_url.slice(32) + '?autoplay=0&controls=0&showinfo=0&rel=0');
          this.html_left_section = this.sanitizer.bypassSecurityTrustHtml(this.eventData.html_left_section);
          this.html_right_section = this.sanitizer.bypassSecurityTrustHtml(this.eventData.html_right_section);
          this.isInactive = false;
			}, err => {
        if(err.status===400){
          this.isInactive = true;
          this.errorEventMessage = "The event does not exist";
        } else{
          this.isInactive = true;
          this.errorEventMessage = "The event is inactive";
        }
    });
  }

}