<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Terms & Conditions</h1>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="overflow: visible;">
    <div class="uk-container">
        <h3 class="title-h3">Welcome to Health EMV</h3>
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item" style="margin-top: 22px; margin-bottom: 22px">
               <p>We at Health EMV USA Inc.  agree to provide various business and payment services (the "Services") to you and your business ("you"). This web page (the "General Terms"), in addition to the documents linked on the left of this web page ("Appendices") are the agreement (the "Agreement") which sets out how our Services will be provided to you. By using the Services, you agree to be bound by the following terms and conditions in this Agreement, including the Acceptable Use Policy and the Privacy Policy described below. You agree that any new features or tools which are added to the Services shall also be subject to the Agreement. You can view the current Agreement at any time by visiting <a style="display: inline-block; color: #7c2cff">https://legal.healthEMV.com/us/terms-of-service/</a>.</p>

                <p>The Agreement contains provisions that govern the resolution of all claims between you and HealthEMV, including an agreement and obligation to arbitrate disputes. The arbitration clause requires you to submit claims that you may have against HealthEMV to binding arbitration and to waive your rights to class-action proceedings.</p>

                <p>In order to provide the Services to you, HealthEMV must comply with various laws, rules and obligations HealthEMV has to others. One of the purposes of the Agreement is to make sure that HealthEMV can comply with these obligations and provide the Services to you.</p>

                <p>Subject to this Agreement, HealthEMV is acting in the capacity of a "Payment Service Provider" (under the Visa Rules) and a "Payment Facilitator" (under the MasterCard Rules) and will provide you the Services as described herein. HealthEMV provides processing services with respect to credit card transactions including Visa U.S.A., Inc. ("Visa"), MasterCard Worldwide ("MasterCard"), DFS Services LLC ("Discover Network"), American Express, JCB, Discover, Diners Club (collectively, "Associations"). We intend for you to be able to accept transactions for all of these Associations, except where industry-specific Association restrictions apply.<br>
                You must read, agree with and accept this Agreement before you may apply for an account or use the Services.</p>
            </div>
        </div>
        <h3 class="title-h3">1. Definitions</h3>
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item" style="margin-top: 22px; margin-bottom: 22px">
               <p>For the purposes of the General Terms, the definitions contained in the "Definitions" section or defined within the body of the Agreement apply to capitalized words contained within, unless the context otherwise requires.</p>

<p>1. <b style="color: #7c2cff">“Agreement”</b> consists of these General Terms, Acceptable Use Policy, Privacy Policy and Financial Services Terms including all appendices, schedules, exhibits and attachments.</p>

<p>2. <b style="color: #7c2cff">“Business Day”</b> means any day other than: Saturday or Sunday; or a day on which banking institutions in the United States are authorized by law to be closed; or a day on which the Federal Reserve Bank is closed.</p>

<p>3. <b style="color: #7c2cff">“Card”</b> means a Visa, MasterCard, American Express or Discover Network credit card or debit card.</p>

<p>4. <b style="color: #7c2cff">“Card-Not-Present”</b> means mail order, telephone order, e-commerce order, internet order, or other transactions that are not Card-Present Transactions.</p>

<p>5. <b style="color: #7c2cff">“Issuer”</b> means an Association member that issued a Card to a Cardholder.</p>
            </div>
        </div>
        <h3 class="title-h3">2. Account Requirements</h3>
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item" style="margin-top: 22px; margin-bottom: 22px">
               <p>You must be 18 years old or older, have a valid SIN, and at least the age of majority in the jurisdiction where you reside and the jurisdiction from which you use the Services.
</p>
            </div>
        </div>
        <h3 class="title-h3">3. Use of the Services</h3>
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item" style="margin-top: 22px; margin-bottom: 22px">
               <p>a. The services are available for use only by businesses, sole proprietors, charities or other entities or persons.</p>

<p>b. You may only use the Services to engage in legitimate, commercial transactions with your Customers ("Transactions") using a Card or information embossed on a Card for payment for goods sold and/or leased or services provided to the Cardholder by you. The Services may not be used to conduct personal or non-commercial transactions, to send money to others, or for any other purpose prohibited by this Agreement.</p>

<p>c. You acknowledge that you know your customers best, and that it is your responsibility to manage your relationship with them. Helcim is not responsible for any of your products or services, or for any of your communications with your customers. You agree that HealthEMV has no way of knowing if a particular Transaction you use the Services for is accurate, and that you are responsible for knowing if a Transaction is erroneous, suspicious or fraudulent and that you are solely responsible for any losses which relate to your Transactions. Helcim may provide information or tools as part of the Services to assist you in determining the risk associated with a given Transaction, but HealthEMV provides no warranty with respect to such information or tools. You agree that the determination to proceed with a Transaction is your responsibility.</p>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

