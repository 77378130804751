// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiKey: 'api:17930bfb23c680444c40a5f618c9c8b9-7cd1ac2b-b5cb8dce',
  url: 'https://api.mailgun.net/v3/sandboxf20942dbf20848cd931bc1215212701d.mailgun.org/messages',
  contantMail: 'aaguilar@znerg.com',
  api: 'https://apidev.healthemv.com/api/send-mrkt-mail',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
