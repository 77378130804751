<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>FAQ</h1>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="overflow: visible;">
    <div class="uk-container">
        <h3 class="title-h3">General Question (waiting for content)</h3>
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item services-details-desc">
                <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        Before you get started
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content show">Credit card processing offers customers both options, first the ability to accept card-present payments in their office locations and second the ability to accept card-not-present payments by billing offices/back offices.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Compatibility with premium plugins
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Ach payment processing provides an alternative to credit cards. ACH is a way of accepting payments from bank accounts (direct payments) by entering routing number and bank account number without using paper checks. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Install theme demo contents
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Easy pay allows customers to capture patient’s credit card information at the time of service to be charged later one time, or it allows to setup recurring payment plans for large deductibles and self pay patients.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Translation and localization services 
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient eligibility offers eligibility verification of patient’s insurance coverage with a simple click. Patient eligibility is the best way to ensure that patient has valid coverage and find details of patient responsibility for co-pays. All commercial and government payers are available. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Live chat support
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient Pay offers our customers with an option to let their patients pay the bills from home or wherever they are by logging into our portal.</p>
                                </li>
                            </ul>
                        </div>
            </div>

            <div class="item services-details-desc">
                <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Before you get started
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Credit card processing offers customers both options, first the ability to accept card-present payments in their office locations and second the ability to accept card-not-present payments by billing offices/back offices.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Compatibility with premium plugins
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Ach payment processing provides an alternative to credit cards. ACH is a way of accepting payments from bank accounts (direct payments) by entering routing number and bank account number without using paper checks. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Install theme demo contents
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Easy pay allows customers to capture patient’s credit card information at the time of service to be charged later one time, or it allows to setup recurring payment plans for large deductibles and self pay patients.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Translation and localization services 
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient eligibility offers eligibility verification of patient’s insurance coverage with a simple click. Patient eligibility is the best way to ensure that patient has valid coverage and find details of patient responsibility for co-pays. All commercial and government payers are available. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Live chat support
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient Pay offers our customers with an option to let their patients pay the bills from home or wherever they are by logging into our portal.</p>
                                </li>
                            </ul>
                        </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->


<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="overflow: visible;">
    <div class="uk-container">
        <h3 class="title-h3">Payment Processes (waiting for content)</h3>
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item services-details-desc">
                <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        Changing the timezone
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content show">Credit card processing offers customers both options, first the ability to accept card-present payments in their office locations and second the ability to accept card-not-present payments by billing offices/back offices.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Developer documentation
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Ach payment processing provides an alternative to credit cards. ACH is a way of accepting payments from bank accounts (direct payments) by entering routing number and bank account number without using paper checks. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Connnectiong social media channels
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Easy pay allows customers to capture patient’s credit card information at the time of service to be charged later one time, or it allows to setup recurring payment plans for large deductibles and self pay patients.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Optimize theme speed & perfomance
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient eligibility offers eligibility verification of patient’s insurance coverage with a simple click. Patient eligibility is the best way to ensure that patient has valid coverage and find details of patient responsibility for co-pays. All commercial and government payers are available. </p>
                                </li>
                            </ul>
                        </div>
            </div>

            <div class="item services-details-desc">
                <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Fully responsive
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Credit card processing offers customers both options, first the ability to accept card-present payments in their office locations and second the ability to accept card-not-present payments by billing offices/back offices.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Translation and localization services
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Ach payment processing provides an alternative to credit cards. ACH is a way of accepting payments from bank accounts (direct payments) by entering routing number and bank account number without using paper checks. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Live chat support
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Easy pay allows customers to capture patient’s credit card information at the time of service to be charged later one time, or it allows to setup recurring payment plans for large deductibles and self pay patients.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        RTL Support now
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient eligibility offers eligibility verification of patient’s insurance coverage with a simple click. Patient eligibility is the best way to ensure that patient has valid coverage and find details of patient responsibility for co-pays. All commercial and government payers are available. </p>
                                </li>
                            </ul>
                        </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->
