<!-- Start Main Banner -->
<div id="home" class="banner-section">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="banner-content" style="display: table; height: 100%;">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Your partner in payment solutions</h1>
                                <p>Our team is experienced with advanced latest technologies and we try to use suitable ones in our client projects! We try not to let your project down anyway!</p>
                                <div class="btn-box">
                                    <a routerLink="/contact" class="uk-button uk-button-default">Get Started</a>
                                    <!--a routerLink="/about" class="uk-button-optional">About Us</a-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="banner-image" style="display: table; height: 100%;">
                    <div style="display: table-cell; vertical-align: middle;">
                        <img src="assets/img/macbook.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner -->
<div style="background-image: url(../../assets/img/bg.svg);background-repeat-y: no-repeat;background-size: contain;">
<!-- Start Feedback Area -->
<section id="testimonials" class="feedback-section uk-process uk-section" style="top: -80px;
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;">
    <div class="uk-container">
        <!--div class="uk-section-title section-title uk-text-center">
            <span>Testimonials</span>
            <h2>We have a great review from our clients</h2>
            <div class="bar"></div>
        </div-->

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <!--i class="flaticon-quote"></i-->
                    <div class="client-info">
                        <img src="assets/img/shop.svg" alt="image">
                        <h3>Retail Payment</h3>
                        <!--span>CTO at Envato</span-->
                    </div>
                    <p>If you are looking for a new way to promote your business that won’t cost you more money, maybe printing is one of the options you won’t resist.</p>
                    <div class="svg-arrow"></div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <!--i class="flaticon-quote"></i-->
                    <div class="client-info">
                        <img src="assets/img/internet.svg" alt="image">
                        <h3>Retail Payment</h3>
                        <!--span>CTO at Envato</span-->
                    </div>
                    <p>If you are looking for a new way to promote your business that won’t cost you more money, maybe printing is one of the options you won’t resist.</p>
                    <div class="svg-arrow"></div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <!--i class="flaticon-quote"></i-->
                    <div class="client-info">
                        <img src="assets/img/phone.svg" alt="image">
                        <h3>Retail Payment</h3>
                        <!--span>CTO at Envato</span-->
                    </div>
                    <p>If you are looking for a new way to promote your business that won’t cost you more money, maybe printing is one of the options you won’t resist.</p>
                    <div class="svg-arrow"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<!-- End Feedback Area -->


<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>We’re proud of the company we’ve built.</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!--div class="icon">
                            <i class="flaticon-quality"></i>
                        </div-->
                        <p>The buying of large-screen TVs has absolutely skyrocketed lately. It seems that everyone wants one – and with good reason. The large-screen TV has come a long way from those faded-out behemoths of old that took up half your living room and never really produced a picture of decent quality. Now, however, especially in combination with HDTV, you can get not only a nice, large picture, but a crisp, clean one too.</p>
                        <br>

                        <!--div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div-->
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" class="about-img1" alt="about-img">
                    <img src="assets/img/about2.jpg" class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">

                    <a routerLink="/about" class="uk-button uk-button-default lax" data-lax-preset="driftLeft">More About Us <i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>WHAT WE DO</span>
                        <h2>Our Services</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="" style="
    display: table;
    width: 100%;
">
                        <div style="display: table-row;">
                        <div style="display: table-cell; padding: 50px;text-align: center;">
                            Payments
                            <p>Learn more</p>
                        </div>
                        <div style="display: table-cell;padding: 50px;text-align: center;">
                            Invoicing
                            <p>Learn more</p></div>
                        <div style="display: table-cell;padding: 50px;text-align: center;">
                            Point of - Sale
                            <p>Learn more</p></div></div>
                        <div  style="display: table-row;">
                        <div style="display: table-cell;padding: 50px;text-align: center;">
                            Customers
                            <p>Learn more</p></div>
                        <div style="display: table-cell;padding: 50px;text-align: center;">
                            Customers
                            <p>Learn more</p></div>
                        <div style="display: table-cell;padding: 50px;text-align: center;">
                            Integrations
                            <p>Learn more</p></div></div>
                    </div>
                </div>
            </div>

            <!--div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" class="about-img1" alt="about-img">
                    <img src="assets/img/about2.jpg" class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">

                    <a routerLink="/about" class="uk-button uk-button-default lax" data-lax-preset="driftLeft">More About Us <i class="flaticon-right"></i></a>
                </div>
            </div-->
        </div>
    </div>
</section>
<!-- End About Area -->

</div>

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-image">
                    <img src="assets/img/about3.jpg" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>WHAT CLIENT SAYS ABOUT US</span>
                        <h2>Our Testimonials</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!--div class="icon">
                            <i class="flaticon-quality"></i>
                        </div-->
                        <p>The buying of large-screen TVs has absolutely skyrocketed lately. It seems that everyone wants one – and with good reason. The large-screen TV has come a long way from those faded-out behemoths of old that took up half your living room and never really produced a picture of decent quality. Now, however, especially in combination with HDTV, you can get not only a nice, large picture, but a crisp, clean one too.</p>

                        <!--div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
<!-- End About Area -->


<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>WHO WE HAVE</span>
                        <h2>Partners</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!--div class="icon">
                            <i class="flaticon-quality"></i>
                        </div-->
                        <p>The buying of large-screen TVs has absolutely skyrocketed lately. It seems that everyone wants one – and with good reason. The large-screen TV has come a long way from those faded-out behemoths of old that took up half your.</p>

                        <!--div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div-->
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->




<!-- Start Contact Area -->
<section id="contact" class="contact-section uk-contact" style="margin-bottom: 100px;">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="contact-image">
                    <img src="assets/img/contact-image.jpg" alt="image">

                    <!--div class="contact-info">
                        <h3>27 Division St, New York, NY 10002, USA</h3>
                        <ul>
                            <li>Email: <a href="mailto:hello@baylin.com">hello@baylin.com</a></li>
                            <li>Phone: <a href="tel:+321948754">+ (321) 948 754</a></li>
                            <li>Fax: <a href="tel:+123849457">+ (123) 849 457</a></li>
                        </ul>
                    </div-->
                </div>
            </div>

            <div class="item" style="background: #4c1b9c">
                <div class="contact-form">
                    <div class="uk-section-title section-title">
                        <span style="color: white;">Let’s Talk</span>
                        <h2 style="color: white;">Get in Touch</h2>
                        <div class="bar" style="background: white;"></div>
                    </div>

                    <form id="contactForm">
                        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                            <div class="item uk-margin">
                                <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
                            </div>

                            <div class="item uk-margin">
                                <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input" placeholder="Phone">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
                            </div>
                        </div>

                        <div class="item">
                            <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
                        </div>
                        
                        <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->