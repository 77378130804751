<!-- Start Main Banner -->
<div id="home" class="banner-section" style="background: #4c1b9c;">
    <div class="uk-container-expand" style="max-width: 1560px;margin:auto;">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s" style="margin-left: 0;">
            <div class="item r-banner">
                <div class="banner-content" style="display: table; height: 100%;">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Online Patient Pay Special Offer</h1>
                                <p>HealthEMV is offering a special 2.9% processing fee for our Online Patient Pay feature. No setup costs - No monthly service fees! Please spend a couple minutes & watch our promotional demo. </p>
                                <div class="btn-box">
                                    <a routerLink="/contact" class="uk-button uk-button-default">Get Started</a>
                                    <!--a routerLink="/about" class="uk-button-optional">About Us</a-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item l-banner">
                <div class="v-container">
                    <div style="position:relative; width: 100%;height: 100%;">
                        <iframe
                            id="player"
                            [src]="videoSourceSanitized"
                            style="width: 100%; height: 100%"
                            frameborder="0"
                            allow="autoplay; encrypted-media"
                            allowfullscreen
                        ></iframe>
                        <button
                            id="play"
                            class="play-btn uk-button uk-button-default uk-button-large"
                            [style.display]="isVideoPlaying ? 'none' : ''"
                            style="position:absolute; z-index:666; font-size: 4rem !important; top:50%; left:50%; transform:translate(-50%, -50%);"
                            (click)='playVideo()'
                        >

            <!--div class="item">
                <div class="banner-image" style="display: flex; justify-content: center; align-items: center;">
                    <div style="display: table-cell; vertical-align: bottom;text-align: end;">
                        <button class="uk-button uk-button-default uk-button-large" style="font-size: 4rem !important;"-->
                            Play
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner -->
<div class="background-image">
<!-- Start Feedback Area -->
<section id="testimonials" class="feedback-section uk-process uk-section" style="/*top: -60px;padding-top: 0px;padding-bottom: 0px;*/position: relative;">
    <div class="uk-container">
        <!--div class="uk-section-title section-title uk-text-center">
            <span>Testimonials</span>
            <h2>We have a great review from our clients</h2>
            <div class="bar"></div>
        </div-->

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item" [routerLink]="['/services']" fragment="pos">
                    <!--i class="flaticon-quote"></i-->
                    <div class="client-info">
                        <div class="svg-icon shop"></div>
                        <h3>POS Payments</h3>
                        <!--span>CTO at Envato</span-->
                    </div>
                    <p>Credit card chip reader hardware (IPP320) integrated via USB to user computer offering patient
collection via Credit/Debit Cards &amp; Patient Checks.</p>
                    <div class="svg-arrow"></div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item" [routerLink]="['/services']" fragment="vp">
                    <!--i class="flaticon-quote"></i-->
                    <div class="client-info">
                        <div class="svg-icon internet"></div>
                        <h3>Virtual Payments</h3>
                        <!--span>CTO at Envato</span-->
                    </div>
                    <p>Capture patient credit cards at time of service for retrieval post-visit.
Set up automated credit card payment plans. 
Offer remote patient pay post-visit.</p>
                    <div class="svg-arrow"></div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item" [routerLink]="['/services']" fragment="eba">
                    <!--i class="flaticon-quote"></i-->
                    <div class="client-info">
                        <div class="svg-icon eligibility"></div>
                        <h3>Eligibility/Benefit Access</h3>
                        <!--span>CTO at Envato</span-->
                    </div>
                    <p>Determine patient eligibility / amount due at time of service. 
Direct access to almost 1000 payers in less than 5 seconds.</p>
                    <div class="svg-arrow"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<!-- End Feedback Area -->


<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="overflow: visible;">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>We’re proud of the company we’ve built.</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!--div class="icon">
                            <i class="flaticon-quality"></i>
                        </div-->
                        <p>HealthEMV is dedicated to remaining a leader in Point-of-Sale/Revenue Management innovation and technology specializing in medical practitioner’s offices and hospital environments. Our goal is to help you improve cash flow by providing you with a comprehensive payment processing system. The HealthEMV system is the fastest, most sensible way to accelerate POS cash flow and reduce cost of collecting patient responsibility post-visit.</p>
                        <br>

                        <!--div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div-->
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img" style="position: relative;">
                    <img src="assets/img/image1.png" class="about-img1" alt="about-img">
                    <img src="assets/img/image2.png" class="about-img2" alt="about-img">
                    <!--img src="assets/img/1.png" class="shape-img" alt="shape"-->

                    <a routerLink="/about" class="uk-button uk-button-default lax" data-lax-preset="driftLeft">More About Us <i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>WHAT WE DO</span>
                        <h2>Our Services</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="our-services-table">
                        <div class="row">
                            <div class="cell">
                                <div>
                                    <img src="assets/img/credit.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Credit Card Payments</p>
                                <span><a [routerLink]="['/services']" fragment="ccp">Learn more</a></span>
                            </div>
                            <div class="cell">
                                <div>
                                    <img src="assets/img/archive-3.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>ACH Payments</p>
                                <span><a [routerLink]="['/services']" fragment="ach">Learn more</a></span>
                            </div>
                            <div class="cell">
                                <div>
                                    <img src="assets/img/check-1.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Easy Pay</p>
                                <span><a [routerLink]="['/services']" fragment="ep">Learn more</a></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell">
                                <div>
                                    <img src="assets/img/user-6.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Benefit Verification/Patient Eligibility</p>
                                <span><a [routerLink]="['/services']" fragment="bvpe">Learn more</a></span></div>
                            <div class="cell">
                                <div>
                                    <img src="assets/img/group-6.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Patient Pay</p>
                                <span><a [routerLink]="['/services']" fragment="pp">Learn more</a></span></div>
                            <div class="cell">
                                <div>
                                    <img src="assets/img/146-sound-waves.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Reports</p>
                                <span><a [routerLink]="['/services']" fragment="repo">Learn more</a></span>
                            </div>
                        </div>
                    </div>

                    <div class="our-services-table mobile">
                        <div class="row">
                            <div class="cell">
                                <div>
                                    <img src="assets/img/credit.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Credit Card Payments</p>
                                <span><a [routerLink]="['/services']" fragment="ccp">Learn more</a></span>
                            </div>
                            <div class="cell">
                                <div>
                                    <img src="assets/img/archive-3.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>ACH Payments</p>
                                <span><a [routerLink]="['/services']" fragment="ach">Learn more</a></span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="cell">
                                <div>
                                    <img src="assets/img/check-1.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Easy Pay</p>
                                <span><a [routerLink]="['/services']" fragment="ep">Learn more</a></span>
                            </div>
                            <div class="cell">
                                <div>
                                    <img src="assets/img/user-6.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Benefit Verification/Patient Eligibility</p>
                                <span><a href="/services#bvpe">Learn more</a></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell">
                                <div>
                                    <img src="assets/img/group-6.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Patient Pay</p>
                                <span><a [routerLink]="['/services']" fragment="pp">Learn more</a></span>
                            </div>
                            <div class="cell">
                                <div>
                                    <img src="assets/img/146-sound-waves.svg" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Reports</p>
                                <span><a [routerLink]="['/services']" fragment="repo">Learn more</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" class="about-img1" alt="about-img">
                    <img src="assets/img/about2.jpg" class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">

                    <a routerLink="/about" class="uk-button uk-button-default lax" data-lax-preset="driftLeft">More About Us <i class="flaticon-right"></i></a>
                </div>
            </div-->
        </div>
    </div>
</section>
<!-- End About Area -->

</div>

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-image" style="padding-bottom: 0;">
                    <img src="assets/img/image-testimonials.png" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content" style="margin: auto;">
                    <div class="uk-section-title section-title">
                        <span>WHAT CLIENT SAYS ABOUT US</span>
                        <h2>Our Testimonials</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!--div class="icon">
                            <i class="flaticon-quality"></i>
                        </div-->
                        <p>Partnered with HealthEMV since 2017 as our POS front end solution in hospital & regional medical groups and clinics.
Looking to next generations of portal offering payment integration to EMR & patient pay interaction with billing system.</p>

                        <div class="signature">
                            NY Presbyterian Hospital Systems / Weill Cornell Medical Center
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
<!-- End About Area -->


<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>Partners</span>
                        <h2>We are proud of the partnerships developed along the way</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!--div class="icon">
                            <i class="flaticon-quality"></i>
                        </div-->
                        <p>HealthEMV is dedicated to remaining a leader in Point-of-Sale/Revenue Management innovation and technology specializing in medical practitioner’s offices and hospital environments</p>

                        <!--div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div-->
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="our-services-table partner-logos">
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.google.com/search?source=univ&tbm=isch&q=nyp+bmh+patient+portal&sa=X&ved=2ahUKEwjzqPuzuqzrAhWbFjQIHQPoDHIQsAR6BAgKEAE&biw=1029&bih=918">
                                    <img src="assets/img/nyp-logo.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.cerner.com/">
                                    <img src="assets/img/cerner.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://paya.com/">
                                    <img src="assets/img/paya.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.availity.com/?gclid=EAIaIQobChMImt-s8bqs6wIVFx6tBh38XAAGEAAYASAAEgLlbfD_BwE">
                                    <img src="assets/img/availity.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.google.com/maps/uv?hl=en&pb=!1s0x80dcdeb77c89eecb%3A0x2ed9718c23791372!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMaMi9ke6AYPUI2gLEI-ShBsYnmyrQNB2kZLyXk%3Dw240-h160-k-no!5schange%20healthcare%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e10!2sAF1QipMaMi9ke6AYPUI2gLEI-ShBsYnmyrQNB2kZLyXk&sa=X&ved=2ahUKEwiJnO6lu6zrAhUHGTQIHXhGBd8QoiowGnoECBMQBg">
                                    <img src="assets/img/change-healthcare.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.tsys.com/">
                                    <img src="assets/img/tsys.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.google.com/maps/uv?hl=en&pb=!1s0x89c22fc41c236453%3A0x7a0c52c26caed5fd!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNAWkV7-KlyXOZFwo1VAXhu6bholG3nhBKvriQo%3Dw263-h160-k-no!5sjs%20stark%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e10!2sAF1QipNAWkV7-KlyXOZFwo1VAXhu6bholG3nhBKvriQo&sa=X&ved=2ahUKEwjV29KIu6zrAhUhO30KHYPiAdcQoiowDHoECBQQBg">
                                    <img src="assets/img/js-stark.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.checkcommerce.com/">
                                    <img src="assets/img/check-commerce.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--div class="about-img">
                    <img src="assets/img/logo-demos.png" alt="image">
                    
                </div-->
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->




<!-- Start Contact Area -->
<section id="contact" class="contact-section uk-contact contact-bg">
    <!--div class="uk-container-expand"-->
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="contact-image">
                    <img src="assets/img/image-contact.png" alt="image">

                    <!--div class="contact-info">
                        <h3>27 Division St, New York, NY 10002, USA</h3>
                        <ul>
                            <li>Email: <a href="mailto:hello@baylin.com">hello@baylin.com</a></li>
                            <li>Phone: <a href="tel:+321948754">+ (321) 948 754</a></li>
                            <li>Fax: <a href="tel:+123849457">+ (123) 849 457</a></li>
                        </ul>
                    </div-->
                </div>
            </div>

            <div class="item" style="background: #4c1b9c">
                <div class="contact-form">
                    <div class="uk-section-title section-title">
                        <span style="color: white;">Let’s Talk</span>
                        <h2 style="color: white;">Get in Touch</h2>
                        <div class="bar" style="background: white;"></div>
                    </div>

                    <form id="contactForm" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
                        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                            <div class="item uk-margin">
                                <input type="text" class="uk-input no-border" name="name" id="name" placeholder="Name" formControlName="name">
                            </div>

                            <div class="item uk-margin">
                                <input type="email" class="uk-input no-border" name="email" id="email" placeholder="Email" formControlName="email">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input no-border" placeholder="Phone" formControlName="phone">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input no-border" name="subject" id="subject" placeholder="Subject" formControlName="subject">
                            </div>
                        </div>

                        <div class="item">
                            <textarea name="message" class="uk-textarea no-border" id="message" cols="30" rows="4" placeholder="Your Message" formControlName="message"></textarea>
                        </div>

                        <div class="row" *ngIf="showMsgSuccess">
                        <div class="col-xs-12">
                          <p class="alert alert-success" style="padding: 10px;
    background-color: #5eba7d;
    color: white;
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;">
                              <strong>Message sent!</strong>
                          </p>
                        </div>
                    </div>

                    <div class="row" *ngIf="showMsgError">
                        <div class="col-xs-12">
                          <p class="alert alert-error" style="padding: 10px;
    background-color: #d1383d;
    color: white;
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;">
                              <strong>Error</strong>
                          </p>
                        </div>
                    </div>
                        
                        <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->