<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services</h1>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>Services</h3>
                        <p>HealthEMV portal is very user-friendly and easy to use portal. It is easy to manage, and needs minimal training. The services offered are listed below.</p>

                        <ul style="padding: 0;
    margin: 20px 0 10px;
    list-style-type: none;
    overflow: hidden;">
                            <li style="padding-bottom: 8px" id="pos"><img src="assets/img/check.svg" alt="img" style="margin-right: 12px;">POS Payments system offers many features, you can accept credit/debit cards through chip reader and checks and at the same time POS Payments system helps you to manage your business better.</li>
                            <li style="padding-bottom: 8px" id="vp"><img src="assets/img/check.svg" alt="img" style="margin-right: 12px;">Virtual Payments feature makes it possible for patients to make payments remotely via our payment portal via credit/debit card information or ACH. It is a very useful tool for back offices and billing offices.</li>
                            <li style="padding-bottom: 8px" id="eba"><img src="assets/img/check.svg" alt="img" style="margin-right: 12px;">Eligibility feature offers real-time eligibility verification. It is the fastest and safest way to check a patient's insurance validity and retrieve patient’s insurance details.</li>
                        </ul>


                        <div style="height: 550px;display: block" class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/services.png" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/services2.png" alt="img">
                            </div>

                            <!--div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div-->
                        </div>
                        <!--h3>Why Choose This Service</h3>
                        <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>

                        

                        <blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote-->

                        <h3 id="ccp">Credit Card Payments </h3>
                        <p>Credit card processing offers customers both options, first the ability to accept card-present payments in their office locations and second the ability to accept card-not-present payments by billing offices/back offices.</p>

                        <h3 id="ach">ACH Payments </h3>
                        <p>Ach payment processing provides an alternative to credit cards. ACH is a way of accepting payments from bank accounts (direct payments) by entering routing number and bank account number without using paper checks.</p>

                        <h3 id="ep">Easy Pay </h3>
                        <p>Easy pay allows customers to capture patient’s credit card information at the time of service to be charged later one time, or it allows to setup recurring payment plans for large deductibles and self pay patients.</p>


                        <h3 id="bvpe">Benefit Verification/Patient Eligibility </h3>
                        <p>Patient eligibility offers eligibility verification of patient’s insurance coverage with a simple click. Patient eligibility is the best way to ensure that patient has valid coverage and find details of patient responsibility for co-pays. All commercial and government payers are available. </p>

                        <h3 id="pp">Patient Pay </h3>
                        <p>Patient Pay offers our customers with an option to let their patients pay the bills from home or wherever they are by logging into our portal.</p>

                        <h3 id="repo">Reports </h3>
                        <p>Reports are an important part of our portal. Reports provide on-line access and important details to billing staff and back office for reconciliation. All reports have option to export into Excel.</p>

                        <!--div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        Credit Card Payments
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content show">Credit card processing offers customers both options, first the ability to accept card-present payments in their office locations and second the ability to accept card-not-present payments by billing offices/back offices.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        ACH Payments 
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Ach payment processing provides an alternative to credit cards. ACH is a way of accepting payments from bank accounts (direct payments) by entering routing number and bank account number without using paper checks. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Easy Pay 
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Easy pay allows customers to capture patient’s credit card information at the time of service to be charged later one time, or it allows to setup recurring payment plans for large deductibles and self pay patients.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Benefit Verification/Patient Eligibility 
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient eligibility offers eligibility verification of patient’s insurance coverage with a simple click. Patient eligibility is the best way to ensure that patient has valid coverage and find details of patient responsibility for co-pays. All commercial and government payers are available. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Patient Pay 
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Patient Pay offers our customers with an option to let their patients pay the bills from home or wherever they are by logging into our portal.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Reports 
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Reports are an important part of our portal. Reports provide on-line access and important details to billing staff and back office for reconciliation. All reports have option to export into Excel.</p>
                                </li>
                            </ul>
                        </div-->
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!--div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><img src="assets/img/search.svg" alt="img"></button>
                        </form>
                    </div-->

                    <div class="widget service_list">
                        <ul>
                            <!--li><a routerLink="/service-details" class="active">Payments <div class="svg-arrow"></div></a></li>
                            <li><a routerLink="/service-details">Keyword Research <div class="svg-arrow"></div></a></li>
                            <li><a routerLink="/service-details">Digital Branding <div class="svg-arrow"></div></a></li>
                            <li><a routerLink="/service-details">Creative Solutions <div class="svg-arrow"></div></a></li>
                            <li><a routerLink="/service-details">Marketing Solutions <div class="svg-arrow"></div></a></li>
                            <li><a routerLink="/service-details">Business Analytics <div class="svg-arrow"></div></a></li-->

                            <li><a [routerLink]="['/services']" fragment="ccp">Credit Card Payments <div class="svg-arrow"></div></a></li>
                            <li><a [routerLink]="['/services']" fragment="ach">ACH Payments <div class="svg-arrow"></div></a></li>
                            <li><a [routerLink]="['/services']" fragment="ep">Easy Pay <div class="svg-arrow"></div></a></li>
                            <li><a [routerLink]="['/services']" fragment="bvpe">Benefit Verification/Patient Eligibility <div class="svg-arrow"></div></a></li>
                            <li><a [routerLink]="['/services']" fragment="pp">Patient Pay <div class="svg-arrow"></div></a></li>
                            <li><a [routerLink]="['/services']" fragment="repo">Reports <div class="svg-arrow"></div></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Download</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="assets/doc/Website_Rev_4.0.pdf" download>HealthEMV Presentation <img src="assets/img/download.svg" alt="img"></a></li>
                            <!--li><a href="assets/doc/Website_Rev_4.0.pdf" download>HealthEMV Brochure <img src="assets/img/download.svg" alt="img"></a></li-->
                            <li><a href="assets/doc/HealthEMV_Cert_Letter.pdf" download>PCI Compliance <img src="assets/img/download.svg" alt="img"></a></li>
                            <!--li><a href="#">Great Technology <img src="assets/img/download.svg" alt="img"></a></li-->
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
