<!-- Start Footer Area -->
<!--footer class="footer-area {{location == '/' ? '' : 'uk-dark'}} uk-footer"-->
<footer class="footer-area uk-footer">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@s">
            <div class="item">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo-footer.png" alt="logo">
                        </a>
                    </div>
                    <!--p>It was a humorously perilous business for both of<br>us. For, before we proceed further.</p-->
                </div>
            </div>


            <div class="item">
                <div class="single-footer-widget" style="width: 50%">
                    <h3>NAVIGATION</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li><a href="/about">About</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/testimonials">Testimonials</a></li>
                        <li><a href="/partners">Partners</a></li>

                    </ul>

                </div>
                <div class="single-footer-widget" style="width: 50%">
                    <h3>CONTACT</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li><a href="tel:+18888860686"><img src="assets/img/phone-call.svg" class="credit-img1" alt="credit-img"> 1-888-886-0686</a></li>
                        <li><a href="mailto:info@healthemv.com"><img src="assets/img/letter.svg" class="credit-img1" alt="credit-img"> info@healthemv.com</a></li>
                        <li><a href="#" style="word-break: break-all;"><img src="assets/img/pin-check.svg" class="credit-img1" alt="credit-img" > California/Delaware/Texas/Wyoming</a></li>

                    </ul>
                </div>
            </div>

        </div>

        <div class="copyright-area">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-3@s">
                <div class="item">
                    <p>© 2020 HEALTHEMV</p>
                </div>

                <div class="item">
                    <ul style="text-align: center;text-transform: uppercase;">
                        <li><a href="/faq">FAQ</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="/terms">Terms & Conditions</a></li>
                    </ul>
                </div>

                <div class="item">
                    
                    <ul class="social">
                        <li><a href="#" target="_blank"><img src="assets/img/github.svg" alt="img"></a></li>
                        <li><a href="#" target="_blank"><img src="assets/img/instagram.svg" alt="img"></a></li>
                        <li><a href="#" target="_blank"><img src="assets/img/twitter.svg" alt="img"></a></li>
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <!--div class="br-line"></div-->
    <!--div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="shape"></div-->
    <!--div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="shape"></div-->
</footer>
<!-- End Footer Area -->
