<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>About</h1>
        <!--ul>
            <li><a routerLink="/">Home</a></li>
            <li>About</li>
        </ul-->
        <div class="bar"></div>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item" >
                <div class="about-image-two uk-text-center">
                    <img src="assets/img/great-experience.png" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content" style="padding-top: 30px;">
                    <div class="uk-section-title section-title">
                        <h2>Leader in POS/Revenue Management Innovation</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text services-details-desc">
                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Hospital Environments
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">HealthEMV is dedicated to remaining a leader in Point-of-Sale/Revenue Management innovation and technology specializing in medical practitioner’s offices and hospital environments. </p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Payment Processing System
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">Our goal is to help you improve cash flow by providing you with comprehensive payment processing system.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Cash Flow
                                         <div class="svg-expansible"></div>
                                    </a>

                                    <p class="accordion-content">The HealthEMV system is the fastest, most sensible way to accelerate POS cash flow and reduce cost of collecting patient responsibility post-visit.</p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">

            <div class="item img-padding mobile">
                <div class="about-image-two uk-text-center img-margin">
                    <img src="assets/img/team.png" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content text-padding" style="padding-top: 30px;">
                    <div class="uk-section-title section-title">
                        <h2>Payment Processing Services</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text" style="padding-bottom: 30px;">
                        <p>HealthEMV provides payment processing services and eligibility verification services through user-friendly HealthEMV web portal. The HealthEMV portal provides innovative solutions for electronically processing transactions within healthcare industry. </p>

                    </div>
                </div>
            </div>

            <div class="item img-padding">
                <div class="about-image-two uk-text-center">
                    <img src="assets/img/team.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Funfacts Area -->
<!--section class="funfacts-area uk-section uk-funfacts">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <img src="assets/img/multiple-11.svg" alt="image">
                    </div>
                    <h3 class="odometer" data-count="143">00</h3>
                    <p>Team Members</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <img src="assets/img/settings-90.svg" alt="image">
                    </div>
                    <h3 class="odometer" data-count="823">00</h3>
                    <p>Completed Projects</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <img src="assets/img/coffe-long.svg" alt="image">
                    </div>
                    <h3 class="odometer" data-count="1538">00</h3>
                    <p>Cups of Coffee</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <img src="assets/img/b-meeting.svg" alt="image">
                    </div>
                    <h3 class="odometer" data-count="29343">00</h3>
                    <p>Happy Clients</p>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Funfacts Area -->

<!-- Start About Area -->
<!--section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item" style="margin: auto;">
                <div class="about-content">
                    <div class="uk-section-title section-title" style="margin-top: 40px;">
                        <h2>Creative Team</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <p>Our values guide us to work together, serve our customers and deliver on our goal. The customer service is committed to provide prompt, courteous and professional answers.</p>

                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="our-services-table">
                        <div class="row">
                            <div class="cell creative">
                                <div>
                                    <img src="assets/img/ceo.png" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Eric Kelley</p>
                                <span>Ceo/Founder</span>
                            </div>
                            <div class="cell creative">
                                <div>
                                    <img src="assets/img/cfo-1.png" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Charlie Wong</p>
                                <span>CFO/Founder</span>
                            </div>
                            <div class="cell creative">
                                <div>
                                    <img src="assets/img/cto-1.png" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Juan Bryan</p>
                                <span>CTO/Founder</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell creative">
                                <div>
                                    <img src="assets/img/marketing-director.png" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Adele Park</p>
                                <span>Learn more</span></div>
                            <div class="cell creative">
                                <div>
                                    <img src="assets/img/cfo-2.png" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Alberta Stevens</p>
                                <span>Learn more</span></div>
                            <div class="cell creative">
                                <div>
                                    <img src="assets/img/cto-2.png" class="credit-img1" alt="credit-img">
                                </div>
                                <p>Laura Hodges</p>
                                <span>Learn more</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End About Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="background-color: #f9f9f9;">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item" style="text-align: center;width: 100%;">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <h2>Our Partners</h2>
                        <div class="bar" style="margin: auto;"></div>
                    </div>

                    <div class="about-text">
                        <p>waiting for content</p>
                    </div>
                </div>
            </div>

            <div class="item" style="text-align: center;width: 100%;margin-top: 30px;margin-bottom: 15px;">
                <div class="about-content" style="margin: auto;flex: none;width: auto;">
                    <div class="our-services-table partner-logos">
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.google.com/search?source=univ&tbm=isch&q=nyp+bmh+patient+portal&sa=X&ved=2ahUKEwjzqPuzuqzrAhWbFjQIHQPoDHIQsAR6BAgKEAE&biw=1029&bih=918">
                                    <img src="assets/img/nyp-logo.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.cerner.com/">
                                    <img src="assets/img/cerner.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://paya.com/">
                                    <img src="assets/img/paya.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.availity.com/?gclid=EAIaIQobChMImt-s8bqs6wIVFx6tBh38XAAGEAAYASAAEgLlbfD_BwE">
                                    <img src="assets/img/availity.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.google.com/maps/uv?hl=en&pb=!1s0x80dcdeb77c89eecb%3A0x2ed9718c23791372!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMaMi9ke6AYPUI2gLEI-ShBsYnmyrQNB2kZLyXk%3Dw240-h160-k-no!5schange%20healthcare%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e10!2sAF1QipMaMi9ke6AYPUI2gLEI-ShBsYnmyrQNB2kZLyXk&sa=X&ved=2ahUKEwiJnO6lu6zrAhUHGTQIHXhGBd8QoiowGnoECBMQBg">
                                    <img src="assets/img/change-healthcare.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.tsys.com/">
                                    <img src="assets/img/tsys.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.google.com/maps/uv?hl=en&pb=!1s0x89c22fc41c236453%3A0x7a0c52c26caed5fd!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNAWkV7-KlyXOZFwo1VAXhu6bholG3nhBKvriQo%3Dw263-h160-k-no!5sjs%20stark%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e10!2sAF1QipNAWkV7-KlyXOZFwo1VAXhu6bholG3nhBKvriQo&sa=X&ved=2ahUKEwjV29KIu6zrAhUhO30KHYPiAdcQoiowDHoECBQQBg">
                                    <img src="assets/img/js-stark.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.checkcommerce.com/">
                                    <img src="assets/img/check-commerce.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--div class="about-img" style="text-align: center;">
                    <img src="assets/img/partner.png" alt="image">
                    
                </div-->
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->










<!--section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Leading the way in Creative Digital Agency</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Best Digital Agency in the World</h3>
                        <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                        <p>We are an experienced and talented team of passionate.</p>
                        <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing.</p>

                        <div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" class="about-img1" alt="about-img">
                    <img src="assets/img/about2.jpg" class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="separate">
    <div class="br-line"></div>
</div>


<section id="clients" class="feedback-area uk-section uk-feedback">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="feedback-img">
                    <img src="assets/img/women.jpg" alt="image">

                    <img src="assets/img/1.png" class="shape-img" alt="image">

                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-multimedia"></i> Watch Video</a>
                </div>
            </div>

            <div class="item">
                <div class="feedback-inner">
                    <div class="uk-section-title section-title">
                        <span>What Client Says About US</span>
                        <h2>Our Testimonials</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>


<section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Our Team</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">View All</a>
        </div>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team2.jpg" alt="image">

            <div class="team-content">
                <h3>David Warner</h3>
                <span>UX/UI Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team3.jpg" alt="image">

            <div class="team-content">
                <h3>Emili Lucy</h3>
                <span>Project Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team4.jpg" alt="image">

            <div class="team-content">
                <h3>Steven Smith</h3>
                <span>Marketing Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team5.jpg" alt="image">

            <div class="team-content">
                <h3>Steve Eva</h3>
                <span>Creative Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>
    </div>
</section>


<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <h3>Subscribe to our newsletter</h3>
            </div>

            <div class="item">
                <form class="newsletter-form">
                    <input type="email" class="uk-input" placeholder="name@gmail.com" name="EMAIL" required autocomplete="off">

                    <button type="submit" class="uk-button uk-button-default">Subscribe Now</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>
</section-->
