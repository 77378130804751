import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';

@Injectable()
export class EventService {

  private API_URL = `${environment.api}`;
  constructor(private http: HttpClient) { }

  getEventService(id_event: string){
		return this.http
			.get(`${this.API_URL}/getEventById`,{
        params: new HttpParams().set('id', id_event)
    });
  }

  getLastIdEventService(){
		return this.http
			.get(`${this.API_URL}/getLastIdEvent`, {});
  }
}