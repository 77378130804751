<!-- Start Mobile Navbar -->
<div id="offcanvas-flip" class="mobile-navbar uk-mobile-navbar" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">

        <button class="uk-offcanvas-close" type="button" uk-close></button>

        <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
            <ul class="uk-navbar-nav">
                <!--li class="uk-active">
                    <a href="#home" uk-icon="icon: triangle-down">Home</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="uk-active">Home One</a>
                            </li>
                            <li>
                                <a routerLink="/digital-agency-demo-2" routerLinkActive="uk-active">Home Two</a>
                            </li>
                            <li>
                                <a routerLink="/digital-agency-demo-3" routerLinkActive="uk-active">Home Three</a>
                            </li>
                            <li>
                                <a routerLink="/digital-agency-demo-4" routerLinkActive="uk-active">Home Four</a>
                            </li>
                            <li>
                                <a routerLink="/business-startups" routerLinkActive="uk-active">Home Five</a>
                            </li>
                            <li>
                                <a routerLink="/seo-agency" routerLinkActive="uk-active">Home Six</a>
                            </li>
                        </ul>
                    </div>
                </li-->
                <li>
                    <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="uk-active" (click)="closeMenu($event)">Home</a>
                </li>
                <li>
                    <a routerLink="/about" routerLinkActive="uk-active" (click)="closeMenu($event)">About</a>
                </li>
                <!--li>
                    <a href="#" uk-icon="icon: triangle-down">Services</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a routerLink="/services" routerLinkActive="uk-active">Services</a>
                            </li>
                            <li>
                                <a routerLink="/service-details" routerLinkActive="uk-active">Service Details</a>
                            </li>
                        </ul>
                    </div>
                </li-->

                <li>
                    <a routerLink="/services" routerLinkActive="uk-active" (click)="closeMenu($event)">Services</a>
                </li>
                <li>
                    <a routerLink="/testimonials" routerLinkActive="uk-active" (click)="closeMenu($event)">Testimonials</a>
                </li>
                <li>
                    <a routerLink="/partners" routerLinkActive="uk-active" (click)="closeMenu($event)">Partners</a>
                </li>
                <li>
                    <a routerLink="/contact" routerLinkActive="uk-active" (click)="closeMenu($event)">Contact</a>
                </li>
            </ul>
        </nav>
    </div>
</div>
<!-- End Mobile Navbar -->

<!-- Start Navbar Area -->
<header class="header-area {{navClass}}" data-uk-sticky="top: 0; animation: uk-animation-slide-top;">
    <div class="uk-container">
        <div class="uk-navbar">
            <div class="logo uk-navbar-left">
                <a routerLink="/">
                    <img src="assets/img/{{logo}}" alt="logo">
                </a>
            </div>

            <div class="uk-navbar-toggle" id="navbar-toggle" uk-toggle="target: #offcanvas-flip">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="navbar uk-navbar-right">
                <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
                    <ul class="uk-navbar-nav">
                        <!--li class="uk-active">
                            <a href="#home" uk-icon="icon: triangle-down">Home</a>
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="uk-active">Home One</a>
                                    </li>
                                    <li>
                                        <a routerLink="/digital-agency-demo-2" routerLinkActive="uk-active">Home Two</a>
                                    </li>
                                    <li>
                                        <a routerLink="/digital-agency-demo-3" routerLinkActive="uk-active">Home Three</a>
                                    </li>
                                    <li>
                                        <a routerLink="/digital-agency-demo-4" routerLinkActive="uk-active">Home Four</a>
                                    </li>
                                    <li>
                                        <a routerLink="/business-startups" routerLinkActive="uk-active">Home Five</a>
                                    </li>
                                    <li>
                                        <a routerLink="/seo-agency" routerLinkActive="uk-active">Home Six</a>
                                    </li>
                                </ul>
                            </div>
                        </li-->
                        <li>
                            <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="uk-active">Home</a>
                        </li>
                        <li>
                            <a routerLink="/about" routerLinkActive="uk-active">About</a>
                        </li>
                        <!--li>
                            <a href="#" uk-icon="icon: triangle-down">Services</a>
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a routerLink="/services" routerLinkActive="uk-active">Services</a>
                                    </li>
                                    <li>
                                        <a routerLink="/service-details" routerLinkActive="uk-active">Service Details</a>
                                    </li>
                                </ul>
                            </div>
                        </li-->

                        <li>
                            <a routerLink="/services" routerLinkActive="uk-active">Services</a>
                        </li>
                        <li>
                            <a routerLink="/testimonials" routerLinkActive="uk-active">Testimonials</a>
                        </li>
                        <li>
                            <a routerLink="/partners" routerLinkActive="uk-active">Partners</a>
                        </li>
                        <li>
                            <a routerLink="/contact" routerLinkActive="uk-active">Contact</a>
                        </li>
                    </ul>
                </nav>

                <!--div class="lang">
                    <form>
                        <div>
                            <select>
                                <option value="eng">En</option>
                                <option value="ge">Ge</option>
                            </select>
                        </div>
                    </form>
                </div-->
            </div>
        </div>
    </div>
</header>
<!-- End Navbar Area -->