<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Testimonials</h1>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="overflow: visible;">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>TESTIMONIALS</span>
                        <h2>We’re proud of the company<br>we’ve built.</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!--div class="icon">
                            <i class="flaticon-quality"></i>
                        </div-->
                        <p>Here’s what our clients  say about HealthEMV payment portal experience</p>
                        <br>

                        <!--div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div-->
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img" style="position: relative;">
                    <img src="assets/img/image-testimonials-page.png" alt="about-img">
                    <!--img src="assets/img/1.png" class="shape-img" alt="shape"-->

                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->


<!-- Start Testimonials Area -->
<section class="testimonials-area uk-testimonials uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>Partnered with HealthEMV since 2017 as our POS front end solution in hospital & regional medical groups and clinics.
Looking to next generations of portal offering payment integration to EMR & patient pay interaction with billing system.</p>
                </div>

                <div class="quotation-profile">
                    <!--img src="assets/img/client1.png" alt="image"-->
                    <h3>NY Presbyterian Hospital Systems / Weill Cornell Medical Center</h3>

                    <!--div class="profile-info">
                        <h3>David Warner</h3>
                        <span>NY Presbyterian Hospital Systems / Weill Cornell Medical Center</span>
                    </div-->
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>Payment portal driven with goal of collecting max $$$'s at POS with minimal effort.
Staff/billers/accounting enjoy multiple location transactions viewable real-time.</p>
                </div>

                <div class="quotation-profile">
                    <!--img src="assets/img/client1.png" alt="image"-->
                    <h3>West County Health Centers</h3>

                    <!--div class="profile-info">
                        <h3>John Lucy</h3>
                        <span>West County Health Centers</span>
                    </div-->
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>Easy to use system allowing us to collect more POS revenue.  
Appreciate the 'Patient Pay' feature allowing patients to pay remotely with integration to our portal.</p>
                </div>

                <div class="quotation-profile">
                    <!--img src="assets/img/client1.png" alt="image"-->
                    <h3>Younis Cardiology</h3>

                    <!--div class="profile-info">
                        <h3>Trent Boult</h3>
                        <span>Younis Cardiology</span>
                    </div-->
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>See many self-pay patients - really appreciate automated credit card payment plan feature.
Service & staff support second-to-none.</p>
                </div>

                <div class="quotation-profile">
                    <!--img src="assets/img/client1.png" alt="image"-->
                    <h3>Weight Loss Management (DFW Bariatric Clinic)</h3>

                    <!--div class="profile-info">
                        <h3>Corey Anderson</h3>
                        <span>Weight Loss Management (DFW Bariatric Clinic)</span>
                    </div-->
                </div>
            </div>

            
            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>Really like the POS system and especially the patient eligibility tool available.
Saves so much time and cost chasing money after visits.</p>
                </div>

                <div class="quotation-profile">
                    <!--img src="assets/img/client1.png" alt="image"-->
                    <h3>Clinton Urgent Care</h3>

                    <!--div class="profile-info">
                        <h3>Corey Anderson</h3>
                        <span>Weight Loss Management (DFW Bariatric Clinic)</span>
                    </div-->
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Testimonials Area -->
