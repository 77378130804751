<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Contact</h1>
    </div>
</section>
<!-- End Page Title Area -->


<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title" style="text-align: left">
                        <h2>HealthEMV, Inc</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <p style="margin-bottom: 25px;">If you would like information about any of HealthEMV services, contact us</p>
                        <!--ul>
                            <li>Benefit Verification/Patient Eligibility</li>
                            <li>Credit Cards Processing</li>
                            <li>ACH (checks) Processing</li>
                            <li>Easy Pay Payment Plans</li>
                            <li>Patient Pay</li>
                        </ul-->

                        <div>
                            <div style="margin-bottom: 15px;"><img src="assets/img/location.svg" class="credit-img1" alt="credit-img" style="max-width:20px;margin-right: 10px;">California/Delaware/Texas/Wyoming</div>
                            <div style="margin-bottom: 15px;"><img src="assets/img/phone-1.svg" class="credit-img1" alt="credit-img"style="max-width:20px;margin-right: 10px;">1-888-886-0686</div>
                            <div style="margin-bottom: 15px;"><img src="assets/img/message.svg" class="credit-img1" alt="credit-img"style="max-width:20px;margin-right: 10px;">info@healthemv.com</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <form id="contactForm" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
                    
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin"> 
                            <input type="text" class="uk-input" name="name" id="name" placeholder="Name" formControlName="name">
                        </div>

                        <div class="item uk-margin">
                            <input type="email" class="uk-input" name="email" id="email" placeholder="Email" formControlName="email">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" placeholder="Phone" name="phone" id="phone" formControlName="phone">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject" formControlName="subject">
                        </div>
                    </div>

                    <div class="item">
                        <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message" formControlName="message"></textarea>
                    </div>

                    <div class="row" *ngIf="showMsgSuccess">
                        <div class="col-xs-12">
                          <p class="alert alert-success" style="padding: 10px;
    background-color: #5eba7d;
    color: white;
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;">
                              <strong>Message sent!</strong>
                          </p>
                        </div>
                    </div>

                    <div class="row" *ngIf="showMsgError">
                        <div class="col-xs-12">
                          <p class="alert alert-error" style="padding: 10px;
    background-color: #d1383d;
    color: white;
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;">
                              <strong>Error</strong>
                          </p>
                        </div>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                </form>
            </div>
        </div>
    </div>
</section>

<!-- End Contact Area -->





<!--section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Let's Talk</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="map-img">
                    <img src="assets/img/map.png" alt="map">

                    <div class="location uk-location1">
                        <a href="#" class="active">
                            <div class="location-info">
                                <h5>New York</h5>
                                <span>198 Collective Street</span>
                            </div>
                        </a>
                    </div>

                    <div class="location uk-location2">
                        <a href="#">
                            <div class="location-info">
                                <h5>London</h5>
                                <span>357/71 Collective Street</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="item">
                <form id="contactForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
                        </div>

                        <div class="item uk-margin">
                            <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" placeholder="Phone">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
                        </div>
                    </div>

                    <div class="item">
                        <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                </form>
            </div>
        </div>
    </div>
</section-->
