import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../email.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-digital-agency-two',
  templateUrl: './digital-agency-two.component.html',
  styleUrls: ['./digital-agency-two.component.scss']
})
export class DigitalAgencyTwoComponent implements OnInit {
  public checkoutForm;
  showMsgSuccess: boolean = false;
  showMsgError: boolean = false;
  isVideoPlaying: boolean = false;
  videoSource: any = 'https://www.youtube.com/embed/hP0vAP--5js';
  videoSourceSanitized: any;

  constructor(private _emailService: EmailService, private formBuilder: FormBuilder, private sanitizer: DomSanitizer) {
    this.checkoutForm = this.formBuilder.group({
      name: null,
      email: null,
      phone: null,
      subject: null,
      message: null
    });
    this.videoSourceSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSource + '?autoplay=0&controls=0&showinfo=0&rel=0');
  }

  ngOnInit() {
  }


  onSubmit(customerData) {
    this._emailService.sendEmail(customerData).subscribe(
      () => { this.showMsgError= false; this.showMsgSuccess= true; console.log('sent'); },
      err =>  { this.showMsgSuccess= false; this.showMsgError= true; console.log(err); } 
    );

    this.checkoutForm.reset();
  }

  playVideo() {
    this.isVideoPlaying = true;
    this.videoSourceSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSource + '?autoplay=1&controls=0&showinfo=0&rel=0');
  }

}
