<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services</h1>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Digital Branding</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Creative Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>Marketing Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-analytics-1"></i>
                    </div>
                    <h3>Business Analytics</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services active">
                    <div class="icon">
                        <i class="flaticon-ux-design"></i>
                    </div>
                    <h3>UX & UI Design</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-camera"></i>
                    </div>
                    <h3>Photography</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-project"></i>
                    </div>
                    <h3>Management</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-chat"></i>
                    </div>
                    <h3>Social Media</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Data Science</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Digital Branding</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Creative Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>Marketing Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-analytics-1"></i>
                    </div>
                    <h3>Business Analytics</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-ux-design"></i>
                    </div>
                    <h3>UX & UI Design</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-camera"></i>
                    </div>
                    <h3>Photography</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-project"></i>
                    </div>
                    <h3>Management</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-chat"></i>
                    </div>
                    <h3>Social Media</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Data Science</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->