import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../email.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public checkoutForm;
  showMsgSuccess: boolean = false;
  showMsgError: boolean = false;

  constructor(private _emailService: EmailService, private formBuilder: FormBuilder) {
    this.checkoutForm = this.formBuilder.group({
      name: null,
      email: null,
      phone: null,
      subject: null,
      message: null
    });
  }

  ngOnInit() {
  }

  onSubmit(customerData) {
    this._emailService.sendEmail(customerData).subscribe(
      () => { this.showMsgError= false; this.showMsgSuccess= true; console.log('sent'); },
      err =>  { this.showMsgSuccess= false; this.showMsgError= true; console.log(err); } 
    );

    this.checkoutForm.reset();
  }

}
