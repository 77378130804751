import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';

//send email in angular 6 EmailService
@Injectable()
export class EmailService {

  constructor(private http: HttpClient) { }

  sendEmail(data) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
   
    return this.http
      .post(
        environment.api+'/send-mrkt-mail',
        {
          "name": data.name,
          "email": data.email,
          "phone": data.phone,
          "subject": data.subject,
          "text": data.message,
        },
        {headers: myheader}
      );
  }
  sendEventEmail(data){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
    return this.http
      .post(
        environment.api+'/sendEventEmail',
        {
          "names": data.names,
          "email": data.email,
          "phone": data.phone,
          "message": data.message,
          "event_id": data.event_id
        },
        {headers: myheader}
      );
  }

}
