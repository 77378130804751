<!-- Start Main Banner -->
<div id="home" class="uk-banner uk-dark main-banner banner-with-form">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="uk-container">
                <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                    <div class="item">
                        <div class="main-banner-content">
                            <h1>Trusted Digital <br>Agency</h1>
                            <p>We have built trust by delivering the best results for clients! Our team is focussed on meeting your online business ultimate targets! Let’s try with us!</p>

                            <a routerLink="/contact" class="uk-button uk-button-default">Get Started</a>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><span uk-icon="play"></span> Watch Video</a>
                        </div>
                    </div>

                    <div class="item">
                        <div class="main-banner-form">
                            <form>
                                <div class="uk-margin">
                                    <input type="text" class="uk-input" placeholder="Your Name">
                                </div>

                                <div class="uk-margin">
                                    <input type="email" class="uk-input" placeholder="Your Email">
                                </div>

                                <div class="uk-margin">
                                    <select class="uk-input">
                                        <option> - Tell us what you need - </option>
                                        <option>a website</option>
                                        <option>a landing page</option>
                                        <option>an iPhone app</option>
                                        <option>an Android app</option>
                                    </select>
                                </div>

                                <div class="uk-margin">
                                    <label>
                                        <input type="checkbox" value="1">
                                        <span>By checking this, you agree to our <a href="#">Terms </a> and <a href="#">Privacy policy</a></span>
                                    </label>
                                </div>

                                <button type="submit" class="uk-button uk-button-default">Get a Quote</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner -->

<!-- Start Features Area -->
<section class="uk-features uk-dark features-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
            <div class="uk-item">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3>Email Marketing</h3>
                    <div class="bar"></div>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur adipiscing elit.</p>

                    <div class="dot-img">
                        <img src="assets/img/dot.png" alt="dot" class="color-dot">
                        <img src="assets/img/white-dot.png" alt="dot" class="white-dot">
                    </div>

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-features-box active">
                    <div class="icon">
                        <i class="flaticon-targeting"></i>
                    </div>
                    <h3>Market Analysis</h3>
                    <div class="bar"></div>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur adipiscing elit.</p>

                    <div class="dot-img">
                        <img src="assets/img/dot.png" alt="dot" class="color-dot">
                        <img src="assets/img/white-dot.png" alt="dot" class="white-dot">
                    </div>

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-magnifying-glass"></i>
                    </div>
                    <h3>Keyword Research</h3>
                    <div class="bar"></div>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur adipiscing elit.</p>

                    <div class="dot-img">
                        <img src="assets/img/dot.png" alt="dot" class="color-dot">
                        <img src="assets/img/white-dot.png" alt="dot" class="white-dot">
                    </div>

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Leading the way in Creative Digital Agency</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Best Digital Agency in the World</h3>
                        <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                        <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing.</p>

                        <div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" class="about-img1" alt="about-img">
                    <img src="assets/img/about2.jpg" class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">

                    <a routerLink="/about" class="uk-button uk-button-default lax" data-lax-preset="driftLeft">More About Us <i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Services Area -->
<section id="services" class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>What We Do</span>
            <h2>Our Services</h2>
            <div class="bar"></div>

            <a routerLink="/services" class="uk-button uk-button-default">All Services</a>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Digital Branding</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Creative Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>Marketing Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-analytics-1"></i>
                    </div>
                    <h3>Business Analytics</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services active">
                    <div class="icon">
                        <i class="flaticon-ux-design"></i>
                    </div>
                    <h3>UX & UI Design</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-camera"></i>
                    </div>
                    <h3>Photography</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-project"></i>
                    </div>
                    <h3>Management</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-chat"></i>
                    </div>
                    <h3>Social Media</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Data Science</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Project Area -->
<section id="project" class="project-area uk-project uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Our Completed Projects</span>
            <h2>Recent Projects</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">All Projects</a>
        </div>
    </div>

    <div class="project-slides owl-carousel owl-theme">
        <div class="single-project">
            <a routerLink="/project-details" class="project-img">
                <img src="assets/img/project1.jpg" alt="image">
            </a>

            <div class="project-content">
                <h3><a routerLink="/project-details">Development</a></h3>
                <ul>
                    <li><a href="#">Web</a></li>
                    <li><a href="#">Mobile</a></li>
                </ul>
            </div>
        </div>

        <div class="single-project">
            <a routerLink="/project-details" class="project-img">
                <img src="assets/img/project2.jpg" alt="image">
            </a>

            <div class="project-content">
                <h3><a routerLink="/project-details">Architecture</a></h3>
                <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Bridge</a></li>
                </ul>
            </div>
        </div>

        <div class="single-project">
            <a routerLink="/project-details" class="project-img">
                <img src="assets/img/project3.jpg" alt="image">
            </a>

            <div class="project-content">
                <h3><a routerLink="/project-details">UX/UI Design</a></h3>
                <ul>
                    <li><a href="#">Web</a></li>
                    <li><a href="#">Mobile</a></li>
                </ul>
            </div>
        </div>

        <div class="single-project">
            <a routerLink="/project-details" class="project-img">
                <img src="assets/img/project1.jpg" alt="image">
            </a>

            <div class="project-content">
                <h3><a routerLink="/project-details">Development</a></h3>
                <ul>
                    <li><a href="#">Web</a></li>
                    <li><a href="#">Mobile</a></li>
                </ul>
            </div>
        </div>

        <div class="single-project">
            <a routerLink="/project-details" class="project-img">
                <img src="assets/img/project3.jpg" alt="image">
            </a>

            <div class="project-content">
                <h3><a routerLink="/project-details">UX/UI Design</a></h3>
                <ul>
                    <li><a href="#">Web</a></li>
                    <li><a href="#">Mobile</a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- End Project Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-section uk-feedback">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="feedback-img">
                    <img src="assets/img/women.jpg" alt="image">

                    <img src="assets/img/1.png" class="shape-img" alt="image">

                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-multimedia"></i> Watch Video</a>
                </div>
            </div>

            <div class="item">
                <div class="feedback-inner">
                    <div class="uk-section-title section-title">
                        <span>What Client Says About US</span>
                        <h2>Our Testimonials</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Team Area -->
<section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Our Team</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">View All</a>
        </div>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team2.jpg" alt="image">

            <div class="team-content">
                <h3>David Warner</h3>
                <span>UX/UI Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team3.jpg" alt="image">

            <div class="team-content">
                <h3>Emili Lucy</h3>
                <span>Project Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team4.jpg" alt="image">

            <div class="team-content">
                <h3>Steven Smith</h3>
                <span>Marketing Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team5.jpg" alt="image">

            <div class="team-content">
                <h3>Steve Eva</h3>
                <span>Creative Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <h3>Subscribe to our newsletter</h3>
            </div>

            <div class="item">
                <form class="newsletter-form">
                    <input type="email" class="uk-input" placeholder="name@gmail.com" name="EMAIL" required autocomplete="off">

                    <button type="submit" class="uk-button uk-button-default">Subscribe Now</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>
</section>
<!-- End Subscribe Area -->

<!-- Start Blog Area -->
<section id="blog" class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Our Company Blog</span>
            <h2>Latest News</h2>
            <div class="bar"></div>

            <a routerLink="/blog" class="uk-button uk-button-default">View All</a>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2019</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">26 April</span>
                    <h3><a routerLink="/blog-details">11 Tools to Help You Easily Create Proposals</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">27 April</span>
                    <h3><a routerLink="/blog-details">The Outlook for Digital Agencies in 4 Charts</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2019</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Let's Talk</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="map-img">
                    <img src="assets/img/map.png" alt="map">

                    <div class="location uk-location1">
                        <a href="#" class="active">
                            <div class="location-info">
                                <h5>New York</h5>
                                <span>198 Collective Street</span>
                            </div>
                        </a>
                    </div>

                    <div class="location uk-location2">
                        <a href="#">
                            <div class="location-info">
                                <h5>London</h5>
                                <span>357/71 Collective Street</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="item">
                <form id="contactForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
                        </div>

                        <div class="item uk-margin">
                            <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" placeholder="Phone">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
                        </div>
                    </div>

                    <div class="item">
                        <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
