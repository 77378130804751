<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title" style="padding-bottom: 0px; padding-top: 100px;">
  <div class="uk-container">
    <div *ngIf='eventData.video_url' class="event-video" style="">
      <iframe
        id="ytplayer-events"
        type="text/html"
        [src]='url_video'
        frameborder="0"
        style="width: 100%; height:100%"
        
      ></iframe>
    </div>
    <h1
      *ngIf='!eventData.video_url'
      style="padding-top: 100px; padding-bottom: 125px;"
    >
      Events
    </h1>
  </div>
</section>
<!-- End Page Title Area -->

<!-- Start Events Area -->
<section id="event" class="event-area uk-event uk-section">
  <div class="uk-container" >
    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s" *ngIf="!isInactive">
      <div class="item" >
        <div class="about-content">
          <div class="uk-section-title section-title" style="text-align: left; margin-bottom: 20px !important;">
            <p style="font-weight: bold">
              HealthEMV is offering a special 2.9% processing fee for our Online<br> Patient Pay feature. No setup costs - No monthly service fees!<br> Please spend a couple minutes & watch our promotional demo.
            </p>
          </div>
          <!--a routerLink="/contact" class="uk-button uk-button-default">
            Play
          </a-->
          <div *ngIf="eventData.html_left_section" [innerHTML]= "html_left_section">
          </div>
        </div>
      </div>
      <div class="item">
        <div style="margin-bottom: 20px; height: 350px;" >
          <iframe
            id="ytplayer-side-form"
            type="text/html"
            [src]='url_video'
            *ngIf = 'eventData.video_url'
            frameborder="0"
            style="width: 100%;height: 100%"
          ></iframe>
        </div>
        <div *ngIf="eventData.html_right_section" [innerHTML]= "html_right_section">
        </div>
        <form id="subscriberForm" [formGroup]="checkoutEventForm" (ngSubmit)="onSubmit(checkoutEventForm.value)">
          <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            <div class="item uk-margin"> 
              <input type="text" class="uk-input" name="names" id="names" placeholder="Names" formControlName="names" required>
            </div>
            <div class="item uk-margin">
              <input type="email" class="uk-input" name="email" id="email" placeholder="Email" formControlName="email" required>
            </div>
            <div class="item uk-margin">
              <input type="text" class="uk-input" placeholder="Phone" name="phone" id="phone" formControlName="phone" required>
            </div>
          </div>
          <div class="item">
            <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Tell us what are you interested in" formControlName="message"></textarea>
          </div>
          <div class="row" *ngIf="showMsgSuccess">
            <div class="col-xs-12">
              <p class="alert alert-success" style="padding: 10px;
                background-color: #5eba7d;
                color: white;
                text-align: center;
                border-radius: 4px;
                margin-top: 10px;">
                <strong>Message sent!</strong>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="showMsgError">
            <div class="col-xs-12">
              <p class="alert alert-error" style="padding: 10px;
                background-color: #d1383d;
                color: white;
                text-align: center;
                border-radius: 4px;
                margin-top: 10px;">
                <strong>Error</strong>
              </p>
            </div>
          </div>
          <button type="submit" class="uk-button uk-button-default">Submit Message</button>
        </form>
      </div>
    </div>
    <div *ngIf="isInactive">
      <h2>{{errorEventMessage}}</h2>
    </div>
  </div>
</section>
<!-- End Events Area -->