<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Partners</h1>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="overflow: visible;">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>PARTNERS</span>
                        <h2>We are proud of the partnerships developed along the way</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <p>HealthEMV is dedicated to remaining a leader in Point-of-Sale/Revenue Management innovation and technology specializing in medical practitioner’s offices and hospital environments</p>
                        <br>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img" style="position: relative;">
                    <img src="assets/img/image1.png" alt="about-img">
                    <!--img src="assets/img/1.png" class="shape-img" alt="shape"-->

                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->


<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section" style="overflow: visible;">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-1@s">
            
            <div class="item">
                <div class="about-content" style="margin: auto;flex: none;width: auto;">
                    <div class="our-services-table partner-logos">
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.google.com/search?source=univ&tbm=isch&q=nyp+bmh+patient+portal&sa=X&ved=2ahUKEwjzqPuzuqzrAhWbFjQIHQPoDHIQsAR6BAgKEAE&biw=1029&bih=918">
                                    <img src="assets/img/nyp-logo.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.cerner.com/">
                                    <img src="assets/img/cerner.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://paya.com/">
                                    <img src="assets/img/paya.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.availity.com/?gclid=EAIaIQobChMImt-s8bqs6wIVFx6tBh38XAAGEAAYASAAEgLlbfD_BwE">
                                    <img src="assets/img/availity.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.google.com/maps/uv?hl=en&pb=!1s0x80dcdeb77c89eecb%3A0x2ed9718c23791372!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMaMi9ke6AYPUI2gLEI-ShBsYnmyrQNB2kZLyXk%3Dw240-h160-k-no!5schange%20healthcare%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e10!2sAF1QipMaMi9ke6AYPUI2gLEI-ShBsYnmyrQNB2kZLyXk&sa=X&ved=2ahUKEwiJnO6lu6zrAhUHGTQIHXhGBd8QoiowGnoECBMQBg">
                                    <img src="assets/img/change-healthcare.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.tsys.com/">
                                    <img src="assets/img/tsys.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell creative">
                                <a href="https://www.google.com/maps/uv?hl=en&pb=!1s0x89c22fc41c236453%3A0x7a0c52c26caed5fd!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNAWkV7-KlyXOZFwo1VAXhu6bholG3nhBKvriQo%3Dw263-h160-k-no!5sjs%20stark%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e10!2sAF1QipNAWkV7-KlyXOZFwo1VAXhu6bholG3nhBKvriQo&sa=X&ved=2ahUKEwjV29KIu6zrAhUhO30KHYPiAdcQoiowDHoECBQQBg">
                                    <img src="assets/img/js-stark.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                            <div class="cell creative">
                                <a href="https://www.checkcommerce.com/">
                                    <img src="assets/img/check-commerce.png" class="credit-img1" alt="credit-img">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->